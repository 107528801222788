@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@import "./global_variables.scss";

html {
  scroll-behavior: smooth;
  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: rgb(241 241 241);
  }

  ::-webkit-scrollbar-thumb {
    background-color: $dark-color;
    border-radius: 20px;
    width: 20px;
    border: 6px solid transparent;
    // background-clip: content-box;
  }
  overflow-x: hidden;
}

button:disabled {
  cursor: not-allowed;
}
body {
  background-color: $primary-color;
  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
}

.customcontainer {
  max-width: 1100px;
}

// ====== font families ======

.poppins {
  font-family: "Poppins", sans-serif;
}

.lato {
  font-family: "Lato", sans-serif;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.pageLoader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

// ====== style elements ======
.cursor {
  cursor: pointer;
}

.transcale {
  transition: transform 0.5s ease-in;

  &:hover {
    transform: scale(0.98);
  }
}

.font-weight-bold {
  font-weight: 700;
}

.bg-primary {
  background-color: $primary-color;
}

.bg-secondary {
  background-color: $secondary-color;
}

.bg-dark {
  background-color: $dark-color;
}

.text-primary {
  color: $primary-color;
}

.text-secondary {
  color: $secondary-color;
}

.text-dark {
  color: $dark-color;
}

.text-danger {
  color: rgb(231, 21, 21);
}

.basicFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 36px;
  border-radius: 43px;
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  cursor: pointer;
  transition: transform 0.5s ease-in;
  border: none;
}

.btn-sm {
  padding: 10px 20px;
}

.btn-primary {
  background-color: $primary-color;
  color: $dark-color;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;

  &:hover {
    transform: scale(0.98);
  }
}

.btn-secondary {
  background-color: $secondary-color;
  color: $primary-color;
  box-sizing: border-box;

  &.borderShow {
    border: 1px solid $primary-color;
  }

  &:hover {
    transform: scale(0.98);
  }
}

.btn-red {
  background-color: $primary-color;
  color: #f36b58;
  box-sizing: border-box;

  &.borderShow {
    border: 1px solid $primary-color;
  }

  &:hover {
    transform: scale(0.98);
  }
}

// ====== Alignment styles ======
